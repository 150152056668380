var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card custom-card"},[_c('div',{staticClass:"card-body w-100"},[_c('div',{staticClass:"mb-4 h4 font-weight-bold text-color-green"},[_vm._v(" Formulir Registrasi Pasien Baru ")]),_c('a-form-item',{attrs:{"label":"Identitas Pasien"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'patient_type',
            {
              rules: [
                {
                  required: true,
                  message: 'Identitas Pasien harus dipilih!',
                },
              ],
            },
          ]),expression:"[\n            'patient_type',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: 'Identitas Pasien harus dipilih!',\n                },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"Pilih Identitas Pasien","options":_vm.identityOptions},on:{"change":_vm.handleIdentitasPasienClick},model:{value:(_vm.formData.patient_type),callback:function ($$v) {_vm.$set(_vm.formData, "patient_type", $$v)},expression:"formData.patient_type"}})],1),(this.formData.kunjungan && this.formData.patient_type)?_c('div',[_c('a-steps',{staticClass:"steps mb-4",attrs:{"current":_vm.currentStep - 1,"progress-dot":""}},_vm._l((_vm.steps),function(step,index){return _c('a-step',{key:index,attrs:{"title":step.title}})}),1),(_vm.currentStep === 1)?_c('div',[_c('div',{staticClass:"h4 font-weight-bold text-dark"},[_vm._v("Identitas Pasien")]),_c('a-form',{staticClass:"form",attrs:{"form":_vm.form1}},[(
                this.formData.patient_type === 'Bayi Baru Lahir' ||
                this.formData.patient_type === 'newborn'
              )?_c('a-form-item',{staticClass:"mt-4"},[_c('p',{staticClass:"m-0",style:({
                  lineHeight: '10px !important',
                })},[_vm._v(" Cari data orang tua/wali pasien ")]),_c('span',{staticStyle:{"font-size":"12px","color":"#667085"}},[_vm._v("Cari data orang tua/wali pasien apabila sudah terdaftar sebagai pasien sebelumnya")]),_c('a-button',{staticClass:"d-flex align-items-center justify-content-between w-100",attrs:{"type":"secondary"},on:{"click":function($event){return _vm.handleToggleModal()}}},[_c('span',[_vm._v(_vm._s(_vm.existedPatient ? _vm.formData.name : "Cari Data Pasien"))]),_c('img',{staticStyle:{"height":"16px","width":"16px"},attrs:{"src":"resources/images/search-icon.svg","alt":"Icon"}})])],1):_vm._e(),(
                this.formData.patient_type === 'Bayi Baru Lahir' ||
                this.formData.patient_type === 'newborn'
              )?_c('a-form-item',[_c('div',[_vm._v(" Hubungan dengan Pasien :"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'parent_relationship',
                  // {
                  //   rules: [
                  //     {
                  //       required: true,
                  //       message: 'Nama Anggota Keluarga harus diisi!',
                  //     },
                  //   ],
                  // },
                ]),expression:"[\n                  'parent_relationship',\n                  // {\n                  //   rules: [\n                  //     {\n                  //       required: true,\n                  //       message: 'Nama Anggota Keluarga harus diisi!',\n                  //     },\n                  //   ],\n                  // },\n                ]"}],staticClass:"w-100",attrs:{"placeholder":"Pilih Hubungan Keluarga","options":_vm.relationshipOptions}})],1):_vm._e(),_c('a-form-item',[_c('div',[_vm._v("Nama Lengkap : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'name',
                  {
                    initialValue: _vm.formData.name,
                    rules: [
                      {
                        required: true,
                        message: 'Nama lengkap harus diisi!',
                      },
                    ],
                  },
                ]),expression:"[\n                  'name',\n                  {\n                    initialValue: formData.name,\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Nama lengkap harus diisi!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"disabled":_vm.existedPatient,"placeholder":"Nama lengkap sesuai identitas"}})],1),_c('a-form-item',[_c('div',[_vm._v("Kewarganegaraan :")]),_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'citizenship',
                  {
                    initialValue: _vm.formData.citizenship,
                    rules: [
                      {
                        required: true,
                        message: 'Kewarganegaraan harus diisi!',
                      },
                    ],
                  },
                ]),expression:"[\n                  'citizenship',\n                  {\n                    initialValue: formData.citizenship,\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Kewarganegaraan harus diisi!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"options":_vm.citizenshipOptions,"default-value":_vm.citizenship,"disabled":_vm.existedPatient},on:{"change":_vm.handleNationality}})],1),(_vm.citizenship === 'WNA')?_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"mb-2"},[_vm._v("WNA memiliki NIK ?")]),_c('a-radio-group',{attrs:{"disabled":_vm.existedPatient,"options":_vm.wnaNikStatusOptions,"default-value":_vm.wnaNikStatus},on:{"change":_vm.handleWNANikStatus},model:{value:(_vm.wnaNikStatus),callback:function ($$v) {_vm.wnaNikStatus=$$v},expression:"wnaNikStatus"}})],1):_vm._e(),(_vm.citizenship === 'WNI' || _vm.wnaNikStatus === 'Ya')?_c('a-form-item',[_c('div',[_c('p',{staticClass:"m-0",style:({
                    lineHeight:
                      _vm.citizenship === 'WNA'
                        ? '10px !important'
                        : '40px !important',
                  })},[_vm._v(" Nomor Induk Kependudukan (NIK) : "),(_vm.citizenship === 'WNI' || _vm.wnaNikStatus === 'Ya')?_c('span',{staticClass:"text-danger"},[_vm._v("*")]):_vm._e()]),(_vm.citizenship === 'WNA')?_c('span',{staticStyle:{"font-size":"12px","color":"#667085"}},[_vm._v("Wajib diisi jika pasien WNA memiliki NIK")]):_vm._e()]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'NIK',
                  {
                    initialValue: _vm.formData.NIK,
                    rules: [
                      {
                        required: _vm.citizenship === 'WNI' ? true : false,
                        message: 'NIK harus diisi!',
                      },
                      {
                        pattern: /^\d{16}$/,
                        message: 'NIK harus berisi 16 angka!',
                      },
                    ],
                  },
                ]),expression:"[\n                  'NIK',\n                  {\n                    initialValue: formData.NIK,\n                    rules: [\n                      {\n                        required: citizenship === 'WNI' ? true : false,\n                        message: 'NIK harus diisi!',\n                      },\n                      {\n                        pattern: /^\\d{16}$/,\n                        message: 'NIK harus berisi 16 angka!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"disabled":_vm.existedPatient,"placeholder":"NIK"}})],1):_vm._e(),(_vm.citizenship === 'WNA')?_c('a-form-item',[_c('div',[_vm._v("Nomor Identitas Lain :")]),_c('a-input',{attrs:{"disabled":_vm.existedPatient,"placeholder":"Nomor Paspor/KITAS (Khusus WNA)"},model:{value:(_vm.formData.another_identity),callback:function ($$v) {_vm.$set(_vm.formData, "another_identity", $$v)},expression:"formData.another_identity"}})],1):_vm._e(),_c('a-form-item',[_c('div',[_vm._v("Nama Ibu Kandung : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'biological_mother_name',
                  {
                    initialValue: _vm.formData.biological_mother_name,
                    rules: [
                      {
                        required: true,
                        message: 'Nama ibu kandung harus diisi!',
                      },
                    ],
                  },
                ]),expression:"[\n                  'biological_mother_name',\n                  {\n                    initialValue: formData.biological_mother_name,\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Nama ibu kandung harus diisi!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"disabled":_vm.existedPatient,"placeholder":"Nama ibu kandung"}})],1),_c('div',{staticClass:"d-flex flex-row justify-content-between"},[_c('a-form-item',{staticStyle:{"width":"40%"}},[_c('div',[_vm._v("Tempat Lahir : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'birth_place',
                    {
                      initialValue: _vm.formData.birth_place,
                      rules: [
                        {
                          required: true,
                          message: 'Tempat lahir harus diisi!',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'birth_place',\n                    {\n                      initialValue: formData.birth_place,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Tempat lahir harus diisi!',\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"disabled":_vm.existedPatient,"placeholder":"Tempat lahir sesuai identitas"}})],1),_c('a-form-item',{staticStyle:{"width":"50%"}},[_c('div',[_vm._v("Tanggal Lahir : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'birth_date',
                    {
                      initialValue: _vm.formData.birth_date,
                      rules: [
                        {
                          required: true,
                          message: 'Tanggal lahir harus diisi!',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'birth_date',\n                    {\n                      initialValue: formData.birth_date,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Tanggal lahir harus diisi!',\n                        },\n                      ],\n                    },\n                  ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":_vm.existedPatient,"placeholder":"Tanggal Lahir"}})],1)],1),_c('a-form-item',[_c('div',[_vm._v("Jenis Kelamin : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'gender',
                  {
                    initialValue: _vm.formData.gender,
                    rules: [
                      {
                        required: true,
                        message: 'Jenis Kelamin harus diisi!',
                      },
                    ],
                  },
                ]),expression:"[\n                  'gender',\n                  {\n                    initialValue: formData.gender,\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Jenis Kelamin harus diisi!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"disabled":_vm.existedPatient,"placeholder":"Jenis Kelamin"}},[_c('a-select-option',{attrs:{"value":"","disabled":""}},[_vm._v("Pilih Jenis Kelamin")]),_c('a-select-option',{attrs:{"value":"Male"}},[_vm._v("Laki-laki")]),_c('a-select-option',{attrs:{"value":"Female"}},[_vm._v("Perempuan")])],1)],1),(
                this.formData.patient_type === 'Bayi Baru Lahir' ||
                this.formData.patient_type === 'newborn'
              )?_c('div',[_c('div',[_vm._v(" Nomor Telepon Seluler : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"width":"100%"}},[_c('a-form-item',{staticClass:"col-xl-12"},[_c('div',{staticClass:"d-flex"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'phone_no',
                        {
                          initialValue: _vm.formData.phone_no,
                          rules: [
                            {
                              initialValue: _vm.formData.phone_no,
                              required: true,
                              message: 'Nomor telepon seluler harus diisi!',
                            },
                            // {
                            //   pattern: /^\d{5,}$/,
                            //   message: 'Nomor telepon tidak valid!',
                            // },
                          ],
                        },
                      ]),expression:"[\n                        'phone_no',\n                        {\n                          initialValue: formData.phone_no,\n                          rules: [\n                            {\n                              initialValue: formData.phone_no,\n                              required: true,\n                              message: 'Nomor telepon seluler harus diisi!',\n                            },\n                            // {\n                            //   pattern: /^\\d{5,}$/,\n                            //   message: 'Nomor telepon tidak valid!',\n                            // },\n                          ],\n                        },\n                      ]"}],attrs:{"disabled":_vm.existedPatient,"placeholder":"0000000000"}})],1)])],1)]):_vm._e(),_c('a-form-item',[_c('div',{staticClass:"one-button-container"},[_c('button',{staticClass:"button next-button",attrs:{"type":"button"},on:{"click":_vm.nextStep}},[_vm._v(" Selanjutnya ")])])])],1)],1):_vm._e(),(_vm.currentStep === 2 && !_vm.existedPatient)?_c('div',[_c('div',{staticClass:"h4 font-weight-bold text-dark"},[_vm._v("Kontak")]),_c('a-form',{attrs:{"form":_vm.form2}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',[_vm._v("Nomor Telepon Rumah :")]),_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"width":"100%"}},[_c('a-form-item',{staticClass:"col-xl-4"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'telephone_no_code',
                        {
                          initialValue: _vm.formData.telephone_no_code,
                          // rules: [
                          //   {
                          //     required: true,
                          //     message: 'Kode negara harus dipilih!',
                          //   },
                          // ],
                        },
                      ]),expression:"[\n                        'telephone_no_code',\n                        {\n                          initialValue: formData.telephone_no_code,\n                          // rules: [\n                          //   {\n                          //     required: true,\n                          //     message: 'Kode negara harus dipilih!',\n                          //   },\n                          // ],\n                        },\n                      ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Kode Negara"},model:{value:(_vm.formData.telephone_no_code),callback:function ($$v) {_vm.$set(_vm.formData, "telephone_no_code", $$v)},expression:"formData.telephone_no_code"}},[_c('a-select-option',{attrs:{"value":"","disabled":""}},[_vm._v("Pilih Kode Negara")]),_vm._l((_vm.phoneCode),function(code){return _c('a-select-option',{key:code.country,attrs:{"value":code.code}},[_vm._v(" "+_vm._s(code.code)+" ")])})],2)],1),_c('a-form-item',{staticClass:"col-xl-8"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'telephone_no',
                        {
                          initialValue: _vm.formData.telephone_no,
                          rules: [
                            {
                              pattern: /^\d{5,}$/,
                              message: 'Nomor telepon tidak valid!',
                            },
                          ],
                        },
                      ]),expression:"[\n                        'telephone_no',\n                        {\n                          initialValue: formData.telephone_no,\n                          rules: [\n                            {\n                              pattern: /^\\d{5,}$/,\n                              message: 'Nomor telepon tidak valid!',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"placeholder":"0000000000"}})],1)],1)]),_c('div',{staticClass:"col-6"},[_c('div',[_vm._v(" Nomor Telepon Seluler : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"width":"100%"}},[_c('a-form-item',{staticClass:"col-xl-4"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'phone_no_code',
                        {
                          initialValue: _vm.formData.phone_no_code,
                          rules: [
                            {
                              required: true,
                              message: 'Kode negara harus dipilih!',
                            },
                          ],
                        },
                      ]),expression:"[\n                        'phone_no_code',\n                        {\n                          initialValue: formData.phone_no_code,\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Kode negara harus dipilih!',\n                            },\n                          ],\n                        },\n                      ]"}],staticStyle:{"width":"100%"},model:{value:(_vm.formData.phone_no_code),callback:function ($$v) {_vm.$set(_vm.formData, "phone_no_code", $$v)},expression:"formData.phone_no_code"}},[_c('a-select-option',{attrs:{"value":"","disabled":""}},[_vm._v("Pilih Kode Negara")]),_vm._l((_vm.phoneCode),function(code){return _c('a-select-option',{key:code.country,attrs:{"value":code.code}},[_vm._v(" "+_vm._s(code.code)+" ")])})],2)],1),_c('a-form-item',{staticClass:"col-xl-8"},[_c('div',{staticClass:"d-flex"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'phone_no',
                          {
                            initialValue: _vm.formData.phone_no,
                            rules: [
                              {
                                initialValue: _vm.formData.phone_no,
                                required: true,
                                message: 'Nomor telepon seluler harus diisi!',
                              },
                              {
                                pattern: /^\d{5,}$/,
                                message: 'Nomor telepon tidak valid!',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'phone_no',\n                          {\n                            initialValue: formData.phone_no,\n                            rules: [\n                              {\n                                initialValue: formData.phone_no,\n                                required: true,\n                                message: 'Nomor telepon seluler harus diisi!',\n                              },\n                              {\n                                pattern: /^\\d{5,}$/,\n                                message: 'Nomor telepon tidak valid!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"placeholder":"0000000000"}})],1)])],1)])]),_c('a-form-item',[_c('div',[_vm._v("Email :")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'email',
                  {
                    initialValue: _vm.formData.email,
                    rules: [
                      { type: 'email', message: 'Alamat Email Tidak Valid' },
                    ],
                  },
                ]),expression:"[\n                  'email',\n                  {\n                    initialValue: formData.email,\n                    rules: [\n                      { type: 'email', message: 'Alamat Email Tidak Valid' },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Alamat Email"}})],1),_c('a-form-item',[_c('div',[_vm._v("Nama Anggota Keluarga :")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'contact_name',
                  // {
                  //   rules: [
                  //     {
                  //       required: true,
                  //       message: 'Nama Anggota Keluarga harus diisi!',
                  //     },
                  //   ],
                  // },
                ]),expression:"[\n                  'contact_name',\n                  // {\n                  //   rules: [\n                  //     {\n                  //       required: true,\n                  //       message: 'Nama Anggota Keluarga harus diisi!',\n                  //     },\n                  //   ],\n                  // },\n                ]"}],attrs:{"placeholder":"Nama Anggota Keluarga"}})],1),_c('a-form-item',[_c('div',[_vm._v("Hubungan Keluarga :")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'contact_relationship',
                  // {
                  //   rules: [
                  //     {
                  //       required: true,
                  //       message: 'Nama Anggota Keluarga harus diisi!',
                  //     },
                  //   ],
                  // },
                ]),expression:"[\n                  'contact_relationship',\n                  // {\n                  //   rules: [\n                  //     {\n                  //       required: true,\n                  //       message: 'Nama Anggota Keluarga harus diisi!',\n                  //     },\n                  //   ],\n                  // },\n                ]"}],staticClass:"w-100",attrs:{"placeholder":"Pilih Hubungan Keluarga","options":_vm.relationshipOptions}})],1),_c('div',[_c('div',[_c('p',{staticClass:"m-0 mb-2"},[_vm._v("Nomor Telepon Keluarga :")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2"},[_c('a-form-item',[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'family_no_code',
                        {
                          initialValue: _vm.formData.family_no_code,
                          // rules: [
                          //   {
                          //     required: true,
                          //     message: 'Kode negara harus dipilih!',
                          //   },
                          // ],
                        },
                      ]),expression:"[\n                        'family_no_code',\n                        {\n                          initialValue: formData.family_no_code,\n                          // rules: [\n                          //   {\n                          //     required: true,\n                          //     message: 'Kode negara harus dipilih!',\n                          //   },\n                          // ],\n                        },\n                      ]"}],staticStyle:{"width":"100%"},model:{value:(_vm.formData.family_no_code),callback:function ($$v) {_vm.$set(_vm.formData, "family_no_code", $$v)},expression:"formData.family_no_code"}},[_c('a-select-option',{attrs:{"value":"","disabled":""}},[_vm._v("Pilih Kode Negara")]),_vm._l((_vm.phoneCode),function(code){return _c('a-select-option',{key:code.country,attrs:{"value":code.code}},[_vm._v(" "+_vm._s(code.code)+" ")])})],2)],1)],1),_c('div',{staticClass:"col-10"},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'contact_number',
                        {
                          initialValue: _vm.formData.family_no,
                          // rules: [
                          //   {
                          //     required: true,
                          //     message:
                          //       'Nomor Telepon Keluarga harus dipilih!',
                          //   },
                          // ],
                        },
                      ]),expression:"[\n                        'contact_number',\n                        {\n                          initialValue: formData.family_no,\n                          // rules: [\n                          //   {\n                          //     required: true,\n                          //     message:\n                          //       'Nomor Telepon Keluarga harus dipilih!',\n                          //   },\n                          // ],\n                        },\n                      ]"}],attrs:{"placeholder":"Nomor Telepon Keluarga"}})],1)],1)])]),_c('div',{staticClass:"two-button-container"},[_c('button',{staticClass:"button prev-button",on:{"click":_vm.prevStep}},[_vm._v(" Kembali ")]),_c('button',{staticClass:"button next-button",attrs:{"type":"primary"},on:{"click":_vm.nextStep}},[_vm._v(" Selanjutnya ")])])],1)],1):_vm._e(),(
            _vm.currentStep === 3 &&
            (_vm.citizenship === 'WNI' || _vm.wnaNikStatus === 'Ya') &&
            !_vm.existedPatient
          )?_c('div',[_c('div',{staticClass:"h4 font-weight-bold text-dark"},[_vm._v("Alamat KTP")]),_c('a-form',{attrs:{"form":_vm.form3}},[_c('a-form-item',[_c('div',[_vm._v("Negara : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'identity_nation',
                  {
                    initialValue: _vm.formData.identity_nation,
                    rules: [
                      { required: true, message: 'Negara harus dipilih!' },
                    ],
                  },
                ]),expression:"[\n                  'identity_nation',\n                  {\n                    initialValue: formData.identity_nation,\n                    rules: [\n                      { required: true, message: 'Negara harus dipilih!' },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Pilih Negara"},model:{value:(_vm.formData.identity_nation),callback:function ($$v) {_vm.$set(_vm.formData, "identity_nation", $$v)},expression:"formData.identity_nation"}},_vm._l((_vm.countries),function(country){return _c('a-select-option',{key:country,attrs:{"value":country}},[_vm._v(" "+_vm._s(country)+" ")])}),1)],1),(_vm.formData.identity_nation === 'Indonesia')?_c('div',[_c('a-form-item',[_c('div',[_vm._v("Provinsi : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'identity_province',
                    {
                      initialValue: _vm.formData.identity_province,
                      rules: [
                        {
                          required: true,
                          message: 'Provinsi harus dipilih!',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'identity_province',\n                    {\n                      initialValue: formData.identity_province,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Provinsi harus dipilih!',\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"placeholder":"Provinsi sesuai identitas"},on:{"change":_vm.handleProvinceClick}},_vm._l((_vm.provinces),function(province){return _c('a-select-option',{key:province.id,attrs:{"value":province.id}},[_vm._v(" "+_vm._s(province.nama)+" ")])}),1)],1),_c('a-form-item',[_c('div',[_vm._v(" Kota / Kabupaten : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'identity_regency',
                    {
                      initialValue: _vm.formData.identity_regency,
                      rules: [
                        {
                          required: true,
                          message: 'Kota / Kabupaten harus dipilih!',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'identity_regency',\n                    {\n                      initialValue: formData.identity_regency,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Kota / Kabupaten harus dipilih!',\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"placeholder":"Kota / Kabupaten sesuai identitas","disabled":!this.regencies.length},on:{"change":_vm.handleRegencyClick}},_vm._l((this.regencies),function(regency){return _c('a-select-option',{key:regency.id,attrs:{"value":regency.id}},[_vm._v(" "+_vm._s(regency.nama)+" ")])}),1)],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('a-form-item',[_c('div',[_vm._v("Kecamatan : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'identity_district',
                        {
                          initialValue: _vm.formData.identity_district,
                          rules: [
                            {
                              required: true,
                              message: 'Kecamatan harus dipilih!',
                            },
                          ],
                        },
                      ]),expression:"[\n                        'identity_district',\n                        {\n                          initialValue: formData.identity_district,\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Kecamatan harus dipilih!',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"disabled":!this.districts.length,"placeholder":"Kecamatan sesuai identitas"},on:{"change":_vm.handleDistrictClick}},_vm._l((this.districts),function(district){return _c('a-select-option',{key:district.id,attrs:{"value":district.id}},[_vm._v(" "+_vm._s(district.nama)+" ")])}),1)],1)],1),_c('div',{staticClass:"col-6"},[_c('a-form-item',[_c('div',[_vm._v(" Kelurahan / Desa : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'identity_village',
                        {
                          initialValue: _vm.formData.identity_village,
                          rules: [
                            {
                              required: true,
                              message: 'Kelurahan / Desa harus dipilih!',
                            },
                          ],
                        },
                      ]),expression:"[\n                        'identity_village',\n                        {\n                          initialValue: formData.identity_village,\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Kelurahan / Desa harus dipilih!',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"disabled":!this.villages.length,"placeholder":"Kelurahan / Desa sesuai identitas"},on:{"change":_vm.handleVillageClick}},_vm._l((this.villages),function(village){return _c('a-select-option',{key:village.id,attrs:{"value":village.id}},[_vm._v(" "+_vm._s(village.nama)+" ")])}),1)],1)],1)]),_c('a-form-item',[_c('div',[_vm._v("Alamat Lengkap : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'identity_address',
                    {
                      initialValue: _vm.formData.identity_address,
                      rules: [
                        { required: true, message: 'Alamat harus diisi!' },
                      ],
                    },
                  ]),expression:"[\n                    'identity_address',\n                    {\n                      initialValue: formData.identity_address,\n                      rules: [\n                        { required: true, message: 'Alamat harus diisi!' },\n                      ],\n                    },\n                  ]"}],attrs:{"placeholder":"Alamat pasien sesuai identitas"}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('a-form-item',[_c('div',[_vm._v("RT : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'identity_RT',
                        {
                          initialValue: _vm.formData.identity_RT,
                          rules: [
                            { required: true, message: 'RT harus diisi!' },
                            {
                              pattern: /^\d{1,}$/,
                              message: 'RT harus berisi angka!',
                            },
                          ],
                        },
                      ]),expression:"[\n                        'identity_RT',\n                        {\n                          initialValue: formData.identity_RT,\n                          rules: [\n                            { required: true, message: 'RT harus diisi!' },\n                            {\n                              pattern: /^\\d{1,}$/,\n                              message: 'RT harus berisi angka!',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"placeholder":"000"}})],1)],1),_c('div',{staticClass:"col-6"},[_c('a-form-item',[_c('div',[_vm._v("RW : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'identity_RW',
                        {
                          initialValue: _vm.formData.identity_RW,
                          rules: [
                            { required: true, message: 'RW harus diisi!' },
                            {
                              pattern: /^\d{1,}$/,
                              message: 'RW harus berisi angka!',
                            },
                          ],
                        },
                      ]),expression:"[\n                        'identity_RW',\n                        {\n                          initialValue: formData.identity_RW,\n                          rules: [\n                            { required: true, message: 'RW harus diisi!' },\n                            {\n                              pattern: /^\\d{1,}$/,\n                              message: 'RW harus berisi angka!',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"placeholder":"000"}})],1)],1)]),_c('a-form-item',[_c('div',[_vm._v("Kode Pos : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'identity_postal_code',
                    {
                      initialValue: _vm.formData.identity_postal_code,
                      rules: [
                        { required: true, message: 'Kode Pos harus diisi!' },
                        {
                          pattern: /^\d{5}$/,
                          message: 'Kode Pos harus berisi 5 angka!',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'identity_postal_code',\n                    {\n                      initialValue: formData.identity_postal_code,\n                      rules: [\n                        { required: true, message: 'Kode Pos harus diisi!' },\n                        {\n                          pattern: /^\\d{5}$/,\n                          message: 'Kode Pos harus berisi 5 angka!',\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"placeholder":"Kode pos sesuai identitas"}})],1)],1):_vm._e(),(_vm.formData.identity_nation === 'Luar Negeri')?_c('div',[_c('a-form-item',{attrs:{"label":"Nama Negara"}},[_c('a-input',{attrs:{"placeholder":"Negara pasien sesuai identitas"},model:{value:(_vm.formData.identity_nationName),callback:function ($$v) {_vm.$set(_vm.formData, "identity_nationName", $$v)},expression:"formData.identity_nationName"}})],1),_c('a-form-item',[_c('div',[_vm._v("Alamat Lengkap : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-textarea',{attrs:{"placeholder":"Alamat pasien sesuai identitas (Jalan, nomor rumah, kota, dan kode pos)","rows":"4"},model:{value:(_vm.formData.identity_addressForeign),callback:function ($$v) {_vm.$set(_vm.formData, "identity_addressForeign", $$v)},expression:"formData.identity_addressForeign"}})],1)],1):_vm._e(),(_vm.formData.identity_nation !== '')?_c('a-form-item',[_c('div',{staticClass:"two-button-container"},[_c('button',{staticClass:"button prev-button",on:{"click":_vm.prevStep}},[_vm._v(" Kembali ")]),_c('button',{staticClass:"button next-button",attrs:{"type":"primary"},on:{"click":_vm.nextStep}},[_vm._v(" Selanjutnya ")])])]):_vm._e()],1)],1):_vm._e(),(
            (_vm.currentStep === 4 && _vm.citizenship === 'WNI') ||
            (_vm.currentStep === 4 &&
              _vm.citizenship === 'WNA' &&
              _vm.wnaNikStatus === 'Ya') ||
            (_vm.currentStep === 3 &&
              _vm.citizenship === 'WNA' &&
              _vm.wnaNikStatus === 'Tidak' &&
              !_vm.existedPatient)
          )?_c('div',[_c('div',{staticClass:"h4 font-weight-bold text-dark"},[_vm._v("Alamat Domisili")]),_c('a-form',{attrs:{"form":_vm.form4}},[(_vm.citizenship === 'WNI' || _vm.wnaNikStatus === 'Ya')?_c('a-form-item',{attrs:{"label":"Alamat Domisili sama dengan Alamat Identitas?"}},[_c('a-radio-group',{model:{value:(_vm.formData.isAddressSame),callback:function ($$v) {_vm.$set(_vm.formData, "isAddressSame", $$v)},expression:"formData.isAddressSame"}},[_c('a-radio',{attrs:{"value":"true"}},[_vm._v("Ya")]),_c('a-radio',{attrs:{"value":"false"}},[_vm._v("Tidak")])],1)],1):_vm._e(),(this.formData.isAddressSame === 'false')?_c('div',[_c('a-form-item',[_c('div',[_vm._v("Negara : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'domicile_nation',
                    {
                      initialValue: _vm.formData.domicile_nation,
                      rules: [
                        { required: true, message: 'Negara harus dipilih!' },
                      ],
                    },
                  ]),expression:"[\n                    'domicile_nation',\n                    {\n                      initialValue: formData.domicile_nation,\n                      rules: [\n                        { required: true, message: 'Negara harus dipilih!' },\n                      ],\n                    },\n                  ]"}],attrs:{"placeholder":"Pilih Negara"},model:{value:(_vm.formData.domicile_nation),callback:function ($$v) {_vm.$set(_vm.formData, "domicile_nation", $$v)},expression:"formData.domicile_nation"}},_vm._l((_vm.citizenship === 'WNI' ||
                    _vm.wnaNikStatus === 'Ya'
                      ? _vm.countries
                      : _vm.countriesCode),function(country){return _c('a-select-option',{key:_vm.citizenship === 'WNI' || _vm.wnaNikStatus === 'Ya'
                        ? country
                        : country.code,attrs:{"value":_vm.citizenship === 'WNI' || _vm.wnaNikStatus === 'Ya'
                        ? country
                        : country.code}},[_vm._v(" "+_vm._s(_vm.citizenship === "WNI" || _vm.wnaNikStatus === "Ya" ? country : country.name)+" ")])}),1)],1),(
                  _vm.formData.domicile_nation === 'Indonesia' ||
                  (_vm.citizenship === 'WNA' && _vm.wnaNikStatus === 'Tidak')
                )?_c('div',[_c('a-form-item',[_c('div',[_vm._v("Provinsi : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),(_vm.citizenship === 'WNA' && _vm.wnaNikStatus === 'Tidak')?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'domicile_province',
                      {
                        initialValue: _vm.formData.domicile_province,
                        rules: [
                          {
                            required: true,
                            message: 'Provinsi harus diisi!',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'domicile_province',\n                      {\n                        initialValue: formData.domicile_province,\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Provinsi harus diisi!',\n                          },\n                        ],\n                      },\n                    ]"}],attrs:{"placeholder":"Provinsi pasien sesuai identitas"}}):_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'domicile_province',
                      {
                        initialValue: _vm.formData.domicile_province,
                        rules: [
                          {
                            required: true,
                            message: 'Provinsi harus dipilih!',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'domicile_province',\n                      {\n                        initialValue: formData.domicile_province,\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Provinsi harus dipilih!',\n                          },\n                        ],\n                      },\n                    ]"}],attrs:{"placeholder":"Pilih provinsi"},on:{"change":_vm.handleProvinceClick}},_vm._l((this.provinces),function(province){return _c('a-select-option',{key:province.id,attrs:{"value":province.id}},[_vm._v(" "+_vm._s(province.nama)+" ")])}),1)],1),_c('a-form-item',[_c('div',[_vm._v(" Kota / Kabupaten : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),(_vm.citizenship === 'WNA' && _vm.wnaNikStatus === 'Tidak')?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'domicile_regency',
                      {
                        initialValue: _vm.formData.domicile_regency,
                        rules: [
                          {
                            required: true,
                            message: 'Kota / Kabupaten harus diisi!',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'domicile_regency',\n                      {\n                        initialValue: formData.domicile_regency,\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Kota / Kabupaten harus diisi!',\n                          },\n                        ],\n                      },\n                    ]"}],attrs:{"placeholder":"Kota / Kabupaten pasien sesuai identitas"}}):_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'domicile_regency',
                      {
                        initialValue: _vm.formData.domicile_regency,
                        rules: [
                          {
                            required: true,
                            message: 'Kota / Kabupaten harus dipilih!',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'domicile_regency',\n                      {\n                        initialValue: formData.domicile_regency,\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Kota / Kabupaten harus dipilih!',\n                          },\n                        ],\n                      },\n                    ]"}],attrs:{"placeholder":"Pilih kota / kabupaten"},on:{"change":_vm.handleRegencyClick}},_vm._l((this.regencies),function(regency){return _c('a-select-option',{key:regency.id,attrs:{"value":regency.id}},[_vm._v(" "+_vm._s(regency.nama)+" ")])}),1)],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('a-form-item',[_c('div',[_vm._v(" Kecamatan : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),(
                          _vm.citizenship === 'WNA' && _vm.wnaNikStatus === 'Tidak'
                        )?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'domicile_district',
                          {
                            initialValue: _vm.formData.domicile_district,
                            rules: [
                              {
                                required: true,
                                message: 'Kecamatan harus diisi!',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'domicile_district',\n                          {\n                            initialValue: formData.domicile_district,\n                            rules: [\n                              {\n                                required: true,\n                                message: 'Kecamatan harus diisi!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"placeholder":"Kecamatan pasien sesuai identitas"}}):_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'domicile_district',
                          {
                            initialValue: _vm.formData.domicile_district,
                            rules: [
                              {
                                required: true,
                                message: 'Kecamatan harus dipilih!',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'domicile_district',\n                          {\n                            initialValue: formData.domicile_district,\n                            rules: [\n                              {\n                                required: true,\n                                message: 'Kecamatan harus dipilih!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"placeholder":"Pilih Kecamatan"},on:{"change":_vm.handleDistrictClick}},_vm._l((this.districts),function(district){return _c('a-select-option',{key:district.id,attrs:{"value":district.id}},[_vm._v(" "+_vm._s(district.nama)+" ")])}),1)],1)],1),_c('div',{staticClass:"col-6"},[_c('a-form-item',[_c('div',[_vm._v(" Kelurahan / Desa : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),(
                          _vm.citizenship === 'WNA' && _vm.wnaNikStatus === 'Tidak'
                        )?_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'domicile_village',
                          {
                            initialValue: _vm.formData.domicile_village,
                            rules: [
                              {
                                required: true,
                                message: 'Desa harus diisi!',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'domicile_village',\n                          {\n                            initialValue: formData.domicile_village,\n                            rules: [\n                              {\n                                required: true,\n                                message: 'Desa harus diisi!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"placeholder":"Desa pasien sesuai identitas"}}):_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'domicile_village',
                          {
                            initialValue: _vm.formData.domicile_village,
                            rules: [
                              {
                                required: true,
                                message: 'Desa harus dipilih!',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'domicile_village',\n                          {\n                            initialValue: formData.domicile_village,\n                            rules: [\n                              {\n                                required: true,\n                                message: 'Desa harus dipilih!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"placeholder":"Pilih Desa"},on:{"change":_vm.handleVillageClick}},_vm._l((this.villages),function(village){return _c('a-select-option',{key:village.id,attrs:{"value":village.id}},[_vm._v(" "+_vm._s(village.nama)+" ")])}),1)],1)],1)]),_c('a-form-item',[_c('div',[_vm._v(" Alamat Lengkap : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'domicile_address',
                      {
                        initialValue: _vm.formData.domicile_address,
                        rules: [
                          { required: true, message: 'Alamat harus diisi!' },
                        ],
                      },
                    ]),expression:"[\n                      'domicile_address',\n                      {\n                        initialValue: formData.domicile_address,\n                        rules: [\n                          { required: true, message: 'Alamat harus diisi!' },\n                        ],\n                      },\n                    ]"}],attrs:{"placeholder":"Alamat pasien sesuai identitas"}})],1),(_vm.citizenship === 'WNI')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('a-form-item',[_c('div',[_vm._v("RT : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'domicile_RT',
                          {
                            initialValue: _vm.formData.domicile_RT,
                            rules: [
                              { required: true, message: 'RT harus diisi!' },
                              {
                                pattern: /^\d{1,}$/,
                                message: 'RT harus berisi angka!',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'domicile_RT',\n                          {\n                            initialValue: formData.domicile_RT,\n                            rules: [\n                              { required: true, message: 'RT harus diisi!' },\n                              {\n                                pattern: /^\\d{1,}$/,\n                                message: 'RT harus berisi angka!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"placeholder":"000"}})],1)],1),_c('div',{staticClass:"col-6"},[_c('a-form-item',[_c('div',[_vm._v("RW : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'domicile_RW',
                          {
                            initialValue: _vm.formData.domicile_RW,
                            rules: [
                              { required: true, message: 'RW harus diisi!' },
                              {
                                pattern: /^\d{1,}$/,
                                message: 'RW harus berisi angka!',
                              },
                            ],
                          },
                        ]),expression:"[\n                          'domicile_RW',\n                          {\n                            initialValue: formData.domicile_RW,\n                            rules: [\n                              { required: true, message: 'RW harus diisi!' },\n                              {\n                                pattern: /^\\d{1,}$/,\n                                message: 'RW harus berisi angka!',\n                              },\n                            ],\n                          },\n                        ]"}],attrs:{"placeholder":"000"}})],1)],1)]):_vm._e(),_c('a-form-item',[_c('div',[_vm._v("Kode Pos : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                      'domicile_postal_code',
                      {
                        initialValue: _vm.formData.domicile_postal_code,
                        rules: [
                          {
                            required: true,
                            message: 'Kode Pos harus diisi!',
                          },
                          {
                            pattern: /^\d{5}$/,
                            message: 'Kode Pos harus berisi 5 angka!',
                          },
                        ],
                      },
                    ]),expression:"[\n                      'domicile_postal_code',\n                      {\n                        initialValue: formData.domicile_postal_code,\n                        rules: [\n                          {\n                            required: true,\n                            message: 'Kode Pos harus diisi!',\n                          },\n                          {\n                            pattern: /^\\d{5}$/,\n                            message: 'Kode Pos harus berisi 5 angka!',\n                          },\n                        ],\n                      },\n                    ]"}],attrs:{"placeholder":"Kode pos sesuai domisili"}})],1)],1):_vm._e(),(_vm.formData.domicile_nation === 'Luar Negeri')?_c('div',[_c('a-form-item',{attrs:{"label":"Nama Negara"}},[_c('a-input',{attrs:{"placeholder":"Provinsi pasien sesuai identitas"},model:{value:(_vm.formData.domicile_nationName),callback:function ($$v) {_vm.$set(_vm.formData, "domicile_nationName", $$v)},expression:"formData.domicile_nationName"}})],1),_c('a-form-item',[_c('div',[_vm._v(" Alamat Lengkap : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-textarea',{attrs:{"placeholder":"Alamat pasien sesuai identitas (Jalan, nomor rumah, kota, dan kode pos)","rows":"4"},model:{value:(_vm.formData.domicile_addressForeign),callback:function ($$v) {_vm.$set(_vm.formData, "domicile_addressForeign", $$v)},expression:"formData.domicile_addressForeign"}})],1)],1):_vm._e()],1):_vm._e(),(
                this.formData.isAddressSame === 'true' ||
                (this.formData.isAddressSame === 'false' &&
                  this.formData.domicile_nation !== '')
              )?_c('a-form-item',[_c('div',{staticClass:"two-button-container"},[_c('button',{staticClass:"button prev-button",on:{"click":_vm.prevStep}},[_vm._v(" Kembali ")]),_c('button',{staticClass:"button next-button",attrs:{"type":"primary"},on:{"click":_vm.nextStep}},[_vm._v(" Selanjutnya ")])])]):_vm._e()],1)],1):_vm._e(),(
            (_vm.currentStep === 5 && _vm.citizenship === 'WNI') ||
            (_vm.currentStep === 5 &&
              _vm.citizenship === 'WNA' &&
              _vm.wnaNikStatus === 'Ya') ||
            (_vm.currentStep === 4 &&
              _vm.citizenship === 'WNA' &&
              _vm.wnaNikStatus === 'Tidak') ||
            (_vm.currentStep === 2 && _vm.existedPatient)
          )?_c('div',[_c('div',{staticClass:"h4 font-weight-bold text-dark"},[_vm._v("Informasi Lainnya")]),_c('a-form',{attrs:{"form":_vm.form5}},[(!_vm.existedPatient)?_c('div',[_c('a-form-item',[_c('div',[_vm._v("Agama : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'religion',
                    {
                      initialValue: _vm.formData.religion,
                      rules: [
                        { required: true, message: 'Agama harus dipilih!' },
                      ],
                    },
                  ]),expression:"[\n                    'religion',\n                    {\n                      initialValue: formData.religion,\n                      rules: [\n                        { required: true, message: 'Agama harus dipilih!' },\n                      ],\n                    },\n                  ]"}],attrs:{"placeholder":"Pilih Agama"},model:{value:(_vm.formData.religion),callback:function ($$v) {_vm.$set(_vm.formData, "religion", $$v)},expression:"formData.religion"}},_vm._l((_vm.religions),function(religion){return _c('a-select-option',{key:religion,attrs:{"value":religion}},[_vm._v(" "+_vm._s(religion)+" ")])}),1)],1),(this.formData.religion === 'Lain-lain')?_c('div',[_c('a-form-item',{attrs:{"label":"Agama Lainnya"}},[_c('a-input',{attrs:{"placeholder":"Isi agama di sini"},model:{value:(_vm.formData.otherReligion),callback:function ($$v) {_vm.$set(_vm.formData, "otherReligion", $$v)},expression:"formData.otherReligion"}})],1)],1):_vm._e(),_c('a-form-item',[_c('div',[_vm._v("Suku :")]),_c('a-input',{attrs:{"placeholder":"Suku"},model:{value:(_vm.formData.ethnic),callback:function ($$v) {_vm.$set(_vm.formData, "ethnic", $$v)},expression:"formData.ethnic"}})],1),_c('a-form-item',[_c('div',[_vm._v(" Bahasa yang Dikuasai : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'language',
                    {
                      initialValue: _vm.formData.language,
                      rules: [
                        { required: true, message: 'Bahasa harus diisi!' },
                      ],
                    },
                  ]),expression:"[\n                    'language',\n                    {\n                      initialValue: formData.language,\n                      rules: [\n                        { required: true, message: 'Bahasa harus diisi!' },\n                      ],\n                    },\n                  ]"}],staticClass:"w-100",attrs:{"placeholder":"Bahasa komunikasi pasien","options":_vm.languageCodeOptions},on:{"change":_vm.languagePatient}})],1),_c('a-form-item',[_c('div',[_vm._v("Pendidikan :")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'education',
                    { initialValue: _vm.formData.education },
                  ]),expression:"[\n                    'education',\n                    { initialValue: formData.education },\n                  ]"}],attrs:{"placeholder":"Pendidikan"},model:{value:(_vm.formData.education),callback:function ($$v) {_vm.$set(_vm.formData, "education", $$v)},expression:"formData.education"}},_vm._l((_vm.educations),function(education){return _c('a-select-option',{key:education,attrs:{"value":education}},[_vm._v(" "+_vm._s(education)+" ")])}),1)],1),_c('a-form-item',[_c('div',[_vm._v("Pekerjaan :")]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['job', { initialValue: _vm.formData.job }]),expression:"['job', { initialValue: formData.job }]"}],attrs:{"placeholder":"Pekerjaan"},model:{value:(_vm.formData.job),callback:function ($$v) {_vm.$set(_vm.formData, "job", $$v)},expression:"formData.job"}},_vm._l((_vm.jobs),function(job){return _c('a-select-option',{key:job,attrs:{"value":job}},[_vm._v(" "+_vm._s(job)+" ")])}),1)],1),(this.formData.job === 'Lain-lain')?_c('div',[_c('a-form-item',{attrs:{"label":"Pekerjaan Lainnya"}},[_c('a-input',{attrs:{"placeholder":"Isi pekerjaan di sini"},model:{value:(_vm.formData.otherJob),callback:function ($$v) {_vm.$set(_vm.formData, "otherJob", $$v)},expression:"formData.otherJob"}})],1)],1):_vm._e(),_c('a-form-item',[_c('div',[_vm._v(" Status Pernikahan : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'martial_status',
                    {
                      initialValue: _vm.formData.martial_status,
                      rules: [
                        {
                          required: true,
                          message: 'Status pernikahan harus dipilih!',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'martial_status',\n                    {\n                      initialValue: formData.martial_status,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Status pernikahan harus dipilih!',\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"placeholder":"Status pernikahan"},model:{value:(_vm.formData.martial_status),callback:function ($$v) {_vm.$set(_vm.formData, "martial_status", $$v)},expression:"formData.martial_status"}},_vm._l((_vm.martialStatus),function(martialStatus){return _c('a-select-option',{key:martialStatus.code,attrs:{"value":martialStatus.code}},[_vm._v(" "+_vm._s(martialStatus.display)+" ")])}),1)],1),_c('a-form-item',[_c('div',[_vm._v(" Cara Pembayaran : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'payment_method',
                    {
                      initialValue: _vm.formData.payment_method,
                      rules: [
                        {
                          required: true,
                          message: 'Metode pembayaran harus dipilih!',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'payment_method',\n                    {\n                      initialValue: formData.payment_method,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Metode pembayaran harus dipilih!',\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"placeholder":"Metode pembayaran pasien"},model:{value:(_vm.formData.payment_method),callback:function ($$v) {_vm.$set(_vm.formData, "payment_method", $$v)},expression:"formData.payment_method"}},_vm._l((_vm.payments),function(payment_method){return _c('a-select-option',{key:payment_method.value,attrs:{"value":payment_method.value}},[_vm._v(" "+_vm._s(payment_method.label)+" ")])}),1)],1),(
                  this.formData.payment_method === 'JKN (BPJS Kesehatan)' ||
                  this.formData.payment_method === 'JKN'
                )?_c('div',[_c('a-form-item',{attrs:{"label":"Nomor BPJS"}},[_c('a-input',{attrs:{"placeholder":"Nomor BPJS"},model:{value:(_vm.formData.bpjs_no),callback:function ($$v) {_vm.$set(_vm.formData, "bpjs_no", $$v)},expression:"formData.bpjs_no"}})],1)],1):_vm._e(),(this.formData.payment_method === 'Asuransi')?_c('div',[_c('a-form-item',{attrs:{"label":"Nama Asuransi"}},[_c('a-input',{attrs:{"placeholder":"Nama Asuransi"},model:{value:(_vm.formData.insurance_name),callback:function ($$v) {_vm.$set(_vm.formData, "insurance_name", $$v)},expression:"formData.insurance_name"}})],1),_c('a-form-item',{attrs:{"label":"Nomor Asuransi"}},[_c('a-input',{attrs:{"placeholder":"Nomor Asuransi"},model:{value:(_vm.formData.insurance_no),callback:function ($$v) {_vm.$set(_vm.formData, "insurance_no", $$v)},expression:"formData.insurance_no"}})],1)],1):_vm._e()],1):_c('div',[_c('a-form-item',[_c('div',[_vm._v(" Cara Pembayaran : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'payment_method',
                    {
                      initialValue: _vm.formData.payment_method,
                      rules: [
                        {
                          required: true,
                          message: 'Metode pembayaran harus dipilih!',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'payment_method',\n                    {\n                      initialValue: formData.payment_method,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Metode pembayaran harus dipilih!',\n                        },\n                      ],\n                    },\n                  ]"}],attrs:{"placeholder":"Metode pembayaran pasien"},model:{value:(_vm.formData.payment_method),callback:function ($$v) {_vm.$set(_vm.formData, "payment_method", $$v)},expression:"formData.payment_method"}},_vm._l((_vm.payments),function(payment_method){return _c('a-select-option',{key:payment_method.value,attrs:{"value":payment_method.value}},[_vm._v(" "+_vm._s(payment_method.label)+" ")])}),1)],1),(
                  this.formData.payment_method === 'JKN (BPJS Kesehatan)' ||
                  this.formData.payment_method === 'JKN'
                )?_c('div',[_c('a-form-item',{attrs:{"label":"Nomor BPJS"}},[_c('a-input',{attrs:{"placeholder":"Nomor BPJS"},model:{value:(_vm.formData.bpjs_no),callback:function ($$v) {_vm.$set(_vm.formData, "bpjs_no", $$v)},expression:"formData.bpjs_no"}})],1)],1):_vm._e(),(this.formData.payment_method === 'Asuransi')?_c('div',[_c('a-form-item',{attrs:{"label":"Nama Asuransi"}},[_c('a-input',{attrs:{"placeholder":"Nama Asuransi"},model:{value:(_vm.formData.insurance_name),callback:function ($$v) {_vm.$set(_vm.formData, "insurance_name", $$v)},expression:"formData.insurance_name"}})],1),_c('a-form-item',{attrs:{"label":"Nomor Asuransi"}},[_c('a-input',{attrs:{"placeholder":"Nomor Asuransi"},model:{value:(_vm.formData.insurance_no),callback:function ($$v) {_vm.$set(_vm.formData, "insurance_no", $$v)},expression:"formData.insurance_no"}})],1)],1):_vm._e()],1),_c('a-form-item',[_c('div',{staticClass:"two-button-container"},[_c('button',{staticClass:"button prev-button",on:{"click":_vm.prevStep}},[_vm._v(" Kembali ")]),(
                    this.formData.patient_type === 'Orang Umum' ||
                    this.formData.patient_type === 'normal'
                  )?_c('button',{staticClass:"button next-button",attrs:{"type":"primary"},on:{"click":_vm.openModal}},[_vm._v(" Simpan ")]):_vm._e(),(
                    this.formData.patient_type === 'Bayi Baru Lahir' ||
                    this.formData.patient_type === 'newborn'
                  )?_c('button',{staticClass:"button next-button",attrs:{"type":"primary"},on:{"click":_vm.nextStep}},[_vm._v(" Selanjutnya ")]):_vm._e()])])],1)],1):_vm._e(),(
            (_vm.currentStep === 6 && _vm.citizenship === 'WNI') ||
            (_vm.currentStep === 6 &&
              _vm.citizenship === 'WNA' &&
              _vm.wnaNikStatus === 'Ya') ||
            (_vm.currentStep === 5 &&
              _vm.citizenship === 'WNA' &&
              _vm.wnaNikStatus === 'Tidak') ||
            (_vm.currentStep === 3 && _vm.existedPatient)
          )?_c('div',[_c('div',{staticClass:"h4 font-weight-bold text-dark"},[_vm._v(" Identitas Bayi Baru Lahir ")]),_c('a-form',{attrs:{"form":_vm.form6}},[_c('a-form-item',[_c('div',[_vm._v("Nama Bayi : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',[_vm._v(" Berupa tulisan \"bayi\" yang diikuti dengan nama lengkap ibu sesuai kartu identitas ")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'baby_name',
                  {
                    initialValue: _vm.formData.baby_name,
                    rules: [
                      { required: true, message: 'Nama bayi harus diisi!' },
                    ],
                  },
                ]),expression:"[\n                  'baby_name',\n                  {\n                    initialValue: formData.baby_name,\n                    rules: [\n                      { required: true, message: 'Nama bayi harus diisi!' },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Bayi Ny..."}})],1),(_vm.citizenship === 'WNI' || _vm.wnaNikStatus === 'Ya')?_c('a-form-item',[_c('div',[_vm._v("NIK Ibu Kandung : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'NIK_mother',
                  {
                    initialValue: _vm.formData.NIK,
                    rules: [
                      {
                        required: true,
                        message: 'NIK ibu kandung harus diisi!',
                      },
                      {
                        pattern: /^\d{16}$/,
                        message: 'NIK harus berisi 16 angka!',
                      },
                    ],
                  },
                ]),expression:"[\n                  'NIK_mother',\n                  {\n                    initialValue: formData.NIK,\n                    rules: [\n                      {\n                        required: true,\n                        message: 'NIK ibu kandung harus diisi!',\n                      },\n                      {\n                        pattern: /^\\d{16}$/,\n                        message: 'NIK harus berisi 16 angka!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Nomor Induk Kependudukan ibu kandung"}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-row justify-content-between"},[_c('a-form-item',[_c('div',[_vm._v("Tanggal Lahir : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'baby_birth_date',
                    {
                      initialValue: _vm.formData.baby_birth_date,
                      rules: [
                        {
                          required: true,
                          message: 'Tanggal lahir harus diisi!',
                        },
                      ],
                    },
                  ]),expression:"[\n                    'baby_birth_date',\n                    {\n                      initialValue: formData.baby_birth_date,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Tanggal lahir harus diisi!',\n                        },\n                      ],\n                    },\n                  ]"}],staticStyle:{"width":"325px"},attrs:{"placeholder":"Tanggal Lahir"}})],1),_c('a-form-item',{staticStyle:{"width":"325px"}},[_c('div',[_vm._v("Jam Lahir : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'baby_birth_time',
                    {
                      initialValue: _vm.formData.baby_birth_time,
                      rules: [
                        { required: true, message: 'Jam lahir harus diisi!' },
                      ],
                    },
                  ]),expression:"[\n                    'baby_birth_time',\n                    {\n                      initialValue: formData.baby_birth_time,\n                      rules: [\n                        { required: true, message: 'Jam lahir harus diisi!' },\n                      ],\n                    },\n                  ]"}],staticClass:"w-100",attrs:{"placeholder":"Pilih Jam"},on:{"change":_vm.checkDateBirth}})],1)],1),_c('a-form-item',[_c('div',[_vm._v("Jenis Kelamin : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'baby_gender',
                  {
                    initialValue: _vm.formData.baby_gender,
                    rules: [
                      {
                        required: true,
                        message: 'Jenis Kelamin harus dipilih!',
                      },
                    ],
                  },
                ]),expression:"[\n                  'baby_gender',\n                  {\n                    initialValue: formData.baby_gender,\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Jenis Kelamin harus dipilih!',\n                      },\n                    ],\n                  },\n                ]"}],attrs:{"placeholder":"Jenis Kelamin"},model:{value:(_vm.formData.baby_gender),callback:function ($$v) {_vm.$set(_vm.formData, "baby_gender", $$v)},expression:"formData.baby_gender"}},[_c('a-select-option',{key:"Male",attrs:{"value":"Male"}},[_vm._v("Laki-laki")]),_c('a-select-option',{key:"Female",attrs:{"value":"Female"}},[_vm._v("Perempuan")])],1)],1),_c('a-form-item',[_c('div',[_vm._v("Kelahiran Ganda :")]),_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'multipleBirth',
                  {
                    initialValue: false,

                    // rules: [
                    //   {
                    //     required: true,
                    //     message: 'Kelahiran Ganda harus dipilih!',
                    //   },
                    // ],
                  },
                ]),expression:"[\n                  'multipleBirth',\n                  {\n                    initialValue: false,\n\n                    // rules: [\n                    //   {\n                    //     required: true,\n                    //     message: 'Kelahiran Ganda harus dipilih!',\n                    //   },\n                    // ],\n                  },\n                ]"}],attrs:{"placeholder":"Pilih Kelahiran Ganda","options":_vm.multipleBirthOptions}})],1),_c('a-form-item',[_c('div',{staticClass:"two-button-container"},[_c('button',{staticClass:"button prev-button",on:{"click":_vm.prevStep}},[_vm._v(" Kembali ")]),_c('button',{staticClass:"button next-button",attrs:{"type":"primary"},on:{"click":_vm.openModal}},[_vm._v(" Simpan ")])])])],1)],1):_vm._e()],1):_vm._e(),_c('a-modal',{attrs:{"title":"Yakin isi formulir sudah sesuai?","confirm-loading":this.confirmLoading,"ok-text":"Ya","cancel-text":"Kembali"},on:{"ok":_vm.submit,"cancel":_vm.closeModal},model:{value:(this.isModalVisible),callback:function ($$v) {_vm.$set(this, "isModalVisible", $$v)},expression:"this.isModalVisible"}},[_c('p',[_vm._v("Mohon cek kembali data diri pasien sebelum menyimpan formulir.")])]),_c('a-modal',{attrs:{"title":"Pilih Pasien","footer":null,"centered":"","width":"1200px"},model:{value:(_vm.toggleModalPatient),callback:function ($$v) {_vm.toggleModalPatient=$$v},expression:"toggleModalPatient"}},[_c('a-input-search',{staticClass:"mb-4",staticStyle:{"width":"350px"},attrs:{"placeholder":"Ketik nama pemeriksaan untuk mencari..."},on:{"change":_vm.searchPatient}}),_c('a-date-picker',{staticStyle:{"width":"350px","margin-left":"10px"},attrs:{"format":"YYYY-MM-DD","placeholder":"Pilih Tanggal Lahir Pasien"},on:{"change":_vm.onFilterChangeDate}}),_c('a-table',{attrs:{"dataSource":_vm.filteredPatientList,"columns":_vm.patientColumns,"rowKey":(record) => record.id},on:{"rowClick":_vm.handleRowDataClick}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }