import apiClient from "@/services/axios"

export const getLanguageCode = async () => {
  const res = await apiClient.get("/api/language-code/languageCode.json")
  if (res) {
    return res.data
  } else {
    return null
  }
}
export const getCountryCode = async () => {
  const res = await apiClient.get("/api/language-code/countryCode.json")
  if (res) {
    return res.data
  } else {
    return null
  }
}
